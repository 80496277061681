import React from "react";
import styled from "styled-components";
import * as Ant from "antd";
import moment from "moment";

import { Font, Color, FontSize } from "./Widget";
import * as Icon from "./Icon";

const TeamList = ({ loading, ...props }) => {
  const teams =
    props.teams &&
    props.teams.filter((record, index) => {
      record.sn = index + 1;
      if (props.searchName !== "") {
        // 過濾搜尋關鍵字 團隊名稱/擁有者/帳號
        if (
          record.name.includes(props.searchName) ||
          record.owner.includes(props.searchName) ||
          record.account.includes(props.searchName)
        ) {
          return true;
        }
        return false;
      }
      return true;
    });

  teams.map((item, index) => {
    item.sn = index + 1;
  });
  const columns = [
    {
      title: "編號",
      key: "id",
      render: (_, { sn }) => {
        return (
          <Font.Body style={{ color: Color.GreyBlack_85 }}>{`${sn}`}</Font.Body>
        );
      },
    },
    {
      title: "團隊名稱",
      key: "name",
      render: (_, { name }) => {
        return (
          <Font.Body style={{ color: Color.GreyBlack_85 }}>{name}</Font.Body>
        );
      },
    },
    {
      title: "擁有者",
      key: "owner",
      render: (_, { owner }) => {
        return (
          <Font.Body style={{ color: Color.GreyBlack_65 }}>{owner}</Font.Body>
        );
      },
    },
    {
      title: "使用者帳號",
      key: "account",
      render: (_, { account }) => {
        return (
          <Font.Body style={{ color: Color.GreyBlack_85 }}>{account}</Font.Body>
        );
      },
    },
    {
      title: "創建時間",
      key: "created",
      render: (_, { created }) => (
        <Font.Body style={{ color: Color.GreyBlack_45 }}>
          {created && moment(created).format("YYYY/MM/DD HH:mm:ss")}
        </Font.Body>
      ),
    },
    {
      title: "最後編輯時間",
      key: "updated",
      render: (_, { updated }) => (
        <Font.Body style={{ color: Color.GreyBlack_45 }}>
          {updated && moment(updated).format("YYYY/MM/DD HH:mm:ss")}
        </Font.Body>
      ),
    },
    {
      title: () => <div style={{ color: Color.Purple }}>編輯</div>,
      key: "edit",
      width: 36,
      align: "center",
      render: (_, record) => (
        <Icon.Edit
          size={FontSize.subTitle}
          color={Color.Purple}
          style={{ width: 24, height: 24, cursor: "pointer" }}
          onClick={() => {
            props.openEditModal(record);
          }}
        />
      ),
    },
    {
      title: () => <div style={{ color: Color.Purple }}>刪除</div>,
      key: "remove",
      width: 36,
      align: "center",
      render: (_, record) => (
        <Icon.Delete
          size={FontSize.head}
          color={Color.Purple}
          style={{ width: 24, height: 24, cursor: "pointer" }}
          onClick={() => {
            props.removeTeam(record);
          }}
        />
      ),
    },
  ];

  return (
    <Wrapper>
      <Ant.Table
        dataSource={teams}
        columns={columns}
        rowKey="userid"
        loading={loading}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-table-pagination.ant-pagination {
    padding-top: 24px;
    padding-bottom: 0;
  }

  .ant-pagination-item {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    border: solid 1px rgba(0, 0, 0, 0.15);
    a {
      background-color: #ffffff;
      color: rgba(0, 0, 0, 0.65);
      transition: all 0.25s linear;
    }
    &.ant-pagination-item-active a {
      background-color: #534ab3;
      color: #ffffff;
    }
  }

  th.ant-table-cell {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    padding: 0;

    &:first-child {
      width: 100px;
      padding: 0px 20px 0px 24px;
    }
    &:nth-child(2) {
      width: 200px;
      padding: 0px 20px 0px 0px;
    }
    &:nth-child(3) {
      width: 200px;
      padding: 0px 20px 0px 0px;
    }
    &:nth-child(4) {
      width: 120px;
      padding: 0px 20px 0px 0px;
    }
    &:nth-child(5) {
      width: 217px;
      padding: 0px 20px 0px 0px;
      text-align: left;
    }
    &:nth-child(6) {
      width: 168px;
    }
    &:nth-child(7) {
      width: 60px;
    }
    &:nth-child(8) {
      width: 60px;
    }
    &:nth-child(9) {
      width: 44px;
      padding: 0px 14px 0px 0px;
    }
  }

  td.ant-table-cell {
    height: 56px;
    padding: 0;

    &:first-child {
      width: 60px;
      padding: 0px 20px 0px 24px;
    }
    &:nth-child(2) {
      width: 330px;
      padding: 0px 20px 0px 0px;
    }
    &:nth-child(3) {
      width: 144px;
      padding: 0px 20px 0px 0px;
    }
    &:nth-child(4) {
      width: 60px;
      padding: 0px 20px 0px 0px;
    }
    &:nth-child(5) {
      width: 217px;
      padding: 0px 20px 0px 0px;
      text-align: left;
    }
    &:nth-child(6) {
      width: 168px;
      div {
        color: rgba(0, 0, 0, 0.45);
      }
    }
    &:nth-child(7) {
      width: 60px;
    }
    &:nth-child(8) {
      width: 60px;
    }
    &:nth-child(9) {
      width: 44px;
      padding: 0px 14px 0px 0px;
    }
  }

  .ant-table-cell {
    cursor: pointer;
  }
`;

const StatusLabel = styled.div`
  display: inline-block;
  border-radius: 18.5px;
  padding: 0px 11px;
  font-size: 13px;
  line-height: 22px;
  height: 22px;
`;

export default TeamList;
