import React from "react";
import * as Ant from "antd";
import styled from "styled-components";

import * as Icon from "../Components/Icon";
import { Color } from "../Components/Widget";

const SearchInput = ({ width = 264, height = 40, placeholder, onChange, value = null }) => {
  let additions = {}
  if (value != null) {
    additions.value = value
  }
  return (
    <SearchInputWrapper>
      <Ant.Input
        className="search-input"
        placeholder={placeholder}
        suffix={
          <Icon.Search
            size={24}
            color={Color.Purple}
            width="auto"
            height="auto"
          />
        }
        size="large"
        style={{ width, height }}
        allowClear
        onChange={onChange}
        {...additions}
      />
    </SearchInputWrapper>
  );
};

const SearchInputWrapper = styled.div`
  .search-input {
    border-radius: 4px;

    .ant-input-suffix {
      display: flex;
      align-items: center;
    }

    span[aria-label="close-circle"] {
      > svg {
        width: 15px;
        height: 15px;
      }
    }
  }
`;

export default SearchInput;
